import React, { useEffect, useState } from 'react';
import './statistics.css';
import LineChart from '../../LineChart/LineChart';
import { BASE_URL } from '../../../constants';
import Select from 'react-select';
import { selectStyles } from '../../Modals/AppointmentModal/AppointmentModal';
import { months } from 'moment';
import AppointmentsStatsList from './AppointmentsStatsList';
import { useParams } from 'react-router';

const ClassesPerStudent = () => {

    const { id, month, year } = useParams()

    const [selectedStudent, setSelectedStudent] = useState();
    const [studentsOptions, setStudentsOptions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [appointmentsList, setAppointmentsList] = useState([]);

    const [refreshStats, setRefreshStats] = useState(false);

    const userToken = localStorage.getItem('frotaToken');

    const months = [
        { label: 'All Months', value: 0 },
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ];

    const getLastFiveYears = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 5 }, (_, index) => ({
            label: `${currentYear - index}`,
            value: currentYear - index,
        }));
    };

    const years = getLastFiveYears();

    useEffect(() => {
        if (month) {
            setSelectedMonth(months[month]);
        } else {
            const currentTime = new Date();
            const monthIndex = currentTime.getMonth() + 1;
            setSelectedMonth(months[monthIndex]);
        }

        if (year) {
            setSelectedYear({ label: year.toString(), value: year })
        } else {
            const currentTime = new Date();
            const currentYear = currentTime.getFullYear();
            setSelectedYear({ label: currentYear.toString(), value: currentYear })
        }
    }, [])

    useEffect(() => {
        const studentOption = studentsOptions?.find(student => student.value === parseInt(id))
        console.log(studentsOptions)
        console.log(studentOption)
        setSelectedStudent(studentOption);
    }, [studentsOptions])

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(`${BASE_URL}admin/users?type=student`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch students');
                }

                const json = await response.json();
                const studentsData = json.data.map(student => ({
                    label: student.name,
                    value: student.id
                }));
                setStudentsOptions(studentsData);
            } catch (error) {
                console.error('Error fetching students:', error);
                // Handle error gracefully
            }
        };

        fetchStudents();
    }, [userToken]); // Include userToken in the dependency array

    const fetchStatsForStudent = async () => {
        try {
            if (!selectedStudent) return; // Make sure a student is selected
            const response = await fetch(`${BASE_URL}admin/monthly_classes/${selectedStudent.value}?type=student${selectedMonth.value === 0 ? '' : `&month=${selectedMonth.value}`}${selectedYear.value === 0 ? '' : `&year=${selectedYear.value}`}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                throw new Error("Failed to get Stats");
            }

            const json = await response.json();

            // console.log(json.data)

            if (json?.data) {
                // Process data to get monthly and yearly stats

                let appointments = [];

                json.data.map((data, index) => {
                    if (data?.appointments?.length > 0) {
                        data.appointments.map(appointment => {
                            if (appointment?.status === 'ended') {
                                appointments.push(appointment);
                            }
                        })
                    }
                })

                // console.log("Appointments: ", appointments);
                setAppointmentsList(appointments);


            }
            // Process json data as needed
        } catch (error) {
            console.error('Error fetching stats:', error);
            // Handle error gracefully
        }
    };

    useEffect(() => {
        fetchStatsForStudent();
    }, [selectedStudent, selectedMonth, selectedYear, userToken, refreshStats]); // Include selectedStudent and userToken in the dependency array

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    return (
        <div className='stats-container'>
            <div className="title-container">
                <h2 className="title">CLASSES PER STUDENT</h2>
            </div>

            <div className='select-time-container'>
                <div className="select-student-container">
                    <label htmlFor="">Select a Student:</label>
                    <Select
                        value={selectedStudent}
                        onChange={(option) => setSelectedStudent(option)}
                        options={studentsOptions}
                        placeholder="Select a Student"
                        styles={selectStyles}
                    />
                </div>

                <div className="select-coach-container">
                    <label htmlFor="">Select Month:</label>
                    <Select
                        value={selectedMonth}
                        onChange={(option) => setSelectedMonth(option)}
                        options={months}
                        placeholder="Select Month"
                        styles={selectStyles}
                    />
                </div>
                <div className="select-coach-container">
                    <label htmlFor="">Select Month:</label>
                    <Select
                        value={selectedYear}
                        onChange={(option) => setSelectedYear(option)}
                        options={years}
                        placeholder="Select Year"
                        styles={selectStyles}
                    />
                </div>
            </div>

            <AppointmentsStatsList
                viewAs='Admin'
                appointments={appointmentsList}
                coach={selectedStudent?.label}
                month={selectedMonth?.label}
                year={selectedYear?.label}
                refreshStats={refreshStats}
                setRefreshStats={setRefreshStats} />

            {/* <LineChart data={dataRange === 'Monthly' ? monthlyData : yearlyData} options={options} /> */}
        </div>
    );
};

export default ClassesPerStudent;
