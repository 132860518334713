import React from 'react';
import { BASE_URL } from '../../../constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const AppointmentsCountList = ({ title, coach, appointments, month, year, viewAs, refreshStats, setRefreshStats, monthId }) => {
    const userToken = localStorage.getItem('frotaToken');

    const navigate = useNavigate();

    const handleMarkPaid = async (ids) => {
        try {
            const response = await fetch(`${BASE_URL}admin/appointment_paid`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(ids),
            });

            if (!response.ok) {
                toast.error("Error marking appointment as paid.");
                throw new Error("Error marking paid.");
            }

            toast.success("Appointment marked as paid.");
            setRefreshStats(!refreshStats);
        } catch (error) {
            console.error('Error: ', error);
        }
    };

    const renderPaidStatus = (appointment) => {
        if (appointment.isPaid) {
            return <span style={{ color: '#3AEB00', fontStyle: 'italic', fontWeight: 600 }}>PAID</span>;
        } else if (viewAs === 'Admin') {
            return (
                <button className="paid-button" onClick={() => handleMarkPaid([appointment?.id])}>
                    Mark as Paid
                </button>
            );
        } else {
            return <span style={{ color: '#FF0000', fontStyle: 'italic', fontWeight: 600 }}>UNPAID</span>;
        }
    };

    const handleClickAppointments = (id) => {

        const url = `/${title === 'Coach' ? 'classes-per-student' : 'classes-per-coach'}/${id}/${monthId}/${year}`

        navigate(url)
    }

    return (
        <div className="appointment-list-container">
            <h2 className="appointment-list-title text-center">
                Stats {coach && `for ${coach}`} {month && month} {year && year}
            </h2>
            <div className="total-container">
                <div className="total-classes">
                    Total Classes
                    <h1>{appointments?.reduce((acc, cur) => acc + (cur?.count || 0), 0)}</h1>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{title === 'Coach' ? 'Student' : 'Coach'}</th>
                            <th>Classes</th>
                            {title !== 'Coach' && (<th>Paid Classes</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {appointments?.map((appointment, index) => (
                            <tr key={index} onClick={() => handleClickAppointments(title === 'Coach' ? appointment?.studentId : appointment?.coachId)}>
                                <td>{title === 'Coach' ? appointment?.studentId : appointment?.coachId}</td>
                                <td>{appointment?.name}</td>
                                <td>{appointment?.count}</td>
                                {title !== 'Coach' && (<td>{appointment?.paidClasses}</td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {
                appointments?.length === 0 && (
                    <div style={{ textAlign: 'center', padding: '10px' }}>No Classes Available.</div>
                )
            }
        </div >
    );
};

export default AppointmentsCountList;
