import React, { useEffect, useState } from 'react';
import { BASE_URL, months } from '../../../constants';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

export const convertTo12HourFormat = (time) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time?.split(':');

    // Convert hours to 12-hour format
    let hour = parseInt(hours, 10);
    // const meridiem = hour >= 12 ? 'PM' : 'AM';
    // hour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

    // Return the formatted time string
    // return `${hour}:${minutes}${meridiem}`;
    return `${hour}:${minutes}`;
};

const AppointmentsByMonths = () => {

    const { month, year } = useParams()

    const [appointments, setAppointments] = useState([]);

    const userToken = localStorage.getItem('frotaToken');


    const fetchStatsByMonth = async () => {
        try {
            const response = await fetch(`${BASE_URL}admin/detailed_monthly_classes?month=${month}&year=${year}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userToken} `
                }
            });

            if (!response.ok) {
                throw new Error("Failed to get Stats");
            }

            const json = await response.json();

            // console.log(json.data)

            if (json?.data) {
                // Process data to get monthly and yearly stats

                console.log("Appointments: ", json?.data);
                setAppointments(json?.data);


            }
            // Process json data as needed
        } catch (error) {
            console.error('Error fetching stats:', error);
            // Handle error gracefully
        }
    };

    useEffect(() => {
        fetchStatsByMonth();
    }, [month, year]); // Include selectedStudent and userToken in the dependency array


    return (
        <div className='appointment-list-container'>
            <h2 className='appintment-list-title'>Stats for {month && " " + months[month]?.label} {year && " " + year}</h2>
            <div className='total-container'>
                <div className='total-classes'>
                    Total Classes
                    <h1> {appointments?.length} </h1>
                </div>
            </div>
            {/* <div className='total-paid-classes'>
                Total Unpaid Classes
                <h1> {appointments.filter(appointment => !appointment.isPaid).length} </h1>
            </div> */}
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Coach</th>
                            <th>Student</th>
                            <th>Class</th>
                            <th>Scheduled At</th>
                            <th>Time</th>
                            {/* {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<th>Paid Status</th>)} */}
                            {/* {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<th>Paid Time</th>)} */}
                        </tr>
                    </thead>
                    <tbody>
                        {appointments?.length > 0 && appointments?.map((appointment, index) => (
                            <tr key={index}>
                                <td>{appointment?.id}</td>
                                <td>{appointment?.coach?.name}</td>
                                <td>{appointment?.student?.name}</td>
                                <td>{appointment?.speciality?.name}</td>
                                {/* <td>{appointment?.scheduledAt}</td> */}
                                <td>{appointment?.scheduledAt}</td>
                                <td>{convertTo12HourFormat(appointment?.start_time) + ' - ' + convertTo12HourFormat(appointment?.end_time)}</td>
                                {/* {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<td>{renderPaidStatus(appointment)}</td>)} */}
                                {/* {(title === 'Coach' || (title === 'Coach' && viewAs === 'Admin')) && (<td>{appointment?.paidTime ? appointment?.paidTime?.split('T')[0] : (<span>Not Paid</span>)}</td>)} */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {appointments.length === 0 && (<div style={{ textAlign: 'center', padding: '10px' }}>No Classes Available.</div>)}

        </div>
    );
};

export default AppointmentsByMonths;
