import React, { useEffect, useState } from 'react';
import './statistics.css';
import { BASE_URL } from '../../../constants';
import Select from 'react-select';
import { selectStyles } from '../../Modals/AppointmentModal/AppointmentModal';
import AppointmentsCountList from './AppointmentsCountList';
import { useNavigate } from 'react-router';

const AllClassesByStudents = () => {
    
    const navigate = useNavigate();
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedYear, setSelectedYear] = useState();
    const [statsByMonths, setStatsByMonths] = useState([]);
    const [appointmentsList, setAppointmentsList] = useState([]);

    const userToken = localStorage.getItem('frotaToken');

    const months = [
        { label: 'All Months', value: 0 },
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ];

    const getLastFiveYears = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 5 }, (_, index) => ({
            label: `${currentYear - index}`,
            value: currentYear - index,
        }));
    };

    const years = getLastFiveYears();

    useEffect(() => {
        const currentDate = new Date();
        setSelectedMonth(months[currentDate.getMonth() + 1]);
        setSelectedYear({ label: currentDate.getFullYear().toString(), value: currentDate.getFullYear() });
    }, []);

    const handleMonthCountClick = (month) => {
        const url = `/classes-stats-by-month/${month}/${selectedYear?.label}`
        navigate(url);
    }

    const fetchStatsForStudents = async () => {
        try {
            const response = await fetch(
                `${BASE_URL}admin/monthly_classes?type=student${selectedMonth?.value === 0 ? '' : `&month=${selectedMonth?.value}`
                }${selectedYear?.value === 0 ? '' : `&year=${selectedYear?.value}`}`,
                {
                    method: 'GET',
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            );

            if (!response.ok) throw new Error('Failed to get stats');

            const { data } = await response.json();
            setAppointmentsList(data || []);
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    const fetchYearlyClassesStats = async () => {
        try {
            const response = await fetch(
                `${BASE_URL}admin/yearly_classes?${selectedYear?.value === 0 ? '' : `year=${selectedYear?.value}`}`,
                {
                    method: 'GET',
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            );

            if (!response.ok) throw new Error('Failed to fetch yearly stats');

            const { data } = await response.json();
            console.log(data)
            setStatsByMonths(data || []);
        } catch (error) {
            console.error('Error fetching yearly stats:', error);
        }
    };

    useEffect(() => {
        fetchStatsForStudents();
    }, [selectedMonth, selectedYear, userToken]);

    useEffect(() => {
        fetchYearlyClassesStats();
    }, [selectedYear, userToken]);

    return (
        <div className="stats-container">
            <div className="title-container">
                <h2 className="title">ALL CLASSES BY STUDENTS</h2>
            </div>

            <div className="select-time-container">
                <div className="select-coach-container">
                    <label>Select Month:</label>
                    <Select
                        value={selectedMonth}
                        onChange={setSelectedMonth}
                        options={months}
                        placeholder="Select Month"
                        styles={selectStyles}
                    />
                </div>

                <div className="select-coach-container">
                    <label>Select Year:</label>
                    <Select
                        value={selectedYear}
                        onChange={setSelectedYear}
                        options={years}
                        placeholder="Select Year"
                        styles={selectStyles}
                    />
                </div>
            </div>

            <AppointmentsCountList
                title="Coach"
                viewAs="Admin"
                appointments={appointmentsList}
                month={selectedMonth?.label}
                year={selectedYear?.label}
                monthId={selectedMonth?.value}
            />

            <div className="stats-by-month-title-container">
                <p className="stats-by-month-title">Classes Stats by Months</p>
            </div>
            <div className="stats-by-month-container">
                {statsByMonths.length > 0 ? (
                    statsByMonths.map((stats) => (
                        <div key={stats.month} className="stats-by-month" onClick={() => handleMonthCountClick(stats.month)}>
                            <p>{months[stats.month]?.label || 'Unknown'}</p>
                            <div className="appointment-count">{stats.appointmentCount}</div>
                        </div>
                    ))
                ) : (
                    <p className="no-data-text">No Data Available.</p>
                )}
            </div>
        </div>
    );
};

export default AllClassesByStudents;
